<template>
  <v-card class="py-3 px-3 text-left checkout-summary" flat>
    <!-- <h2>{{$t('cart.checkoutSummary.cart')}}</h2> -->
    <v-card-text>
      <v-row no-gutters class="d-flex justify-space-between align-top">
        <strong class="summary-text">{{
          $t("cart.checkoutSummary.itemsTotal")
        }}</strong>
        <strong class="summary-value">
          {{ $n(netTotal, "currency") }}
        </strong>
      </v-row>
      <v-row no-gutters class="d-flex justify-space-between align-top ">
        <span class="summary-text">{{
          $t("cart.checkoutSummary.itemsTotalByWeight")
        }}</span>
        <span class="summary-value">{{
          $n(orderCart.totalPriceVariable, "currency")
        }}</span>
      </v-row>

      <v-row no-gutters class="d-flex justify-space-between align-top">
        <strong class="summary-text">{{
          $t("cart.checkoutSummary.packageTotal")
        }}</strong>
        <strong class="summary-value">{{
          $n(orderCart.packageTotal, "currency")
        }}</strong>
      </v-row>

      <v-row
        no-gutters
        class="d-flex justify-space-between align-top "
        v-for="orderPackage in orderCart.orderPackages"
        :key="orderPackage.orderPackageId"
      >
        <span class="summary-text"
          >{{ orderPackage.package.descr }} <br />
          ({{ orderPackage.quantity }}x{{
            $n(orderPackage.package.cost, "currency")
          }})
        </span>
        <span class="summary-value">{{
          $n(orderPackage.grossTotal, "currency")
        }}</span>
      </v-row>

      <v-row
        no-gutters
        v-for="gift in orderCart.giftCertificates"
        :key="gift.giftCertificateId"
        class="d-flex align-center"
      >
        <v-btn icon @click="removeGiftCode(gift.userGiftCertificateId)" right>
          <v-icon color="grey lighten-1" x-small>$delete</v-icon>
        </v-btn>
        <span>{{ gift.name }}</span>
      </v-row>
      <v-row
        no-gutters
        class=" d-flex justify-space-between align-top"
        v-for="gift in orderCart.giftCertificatesToGenerate"
        :key="gift.giftCertificateId"
      >
        <span
          class="summary-text px-2 white--text text-uppercase primary rounded-pill"
          >{{ gift.name }}</span
        >
        <span class="summary-value" v-if="gift.giftTotal">
          {{ $n(gift.giftTotal, "currency") }}</span
        >
      </v-row>

      <v-row
        no-gutters
        class="d-flex justify-space-between align-top"
        v-if="orderCart.deliveryFee > 0"
      >
        <strong class="summary-text">{{
          $t("cart.checkoutSummary.deliveryCharge")
        }}</strong>
        <strong class="summary-value">{{
          $n(orderCart.deliveryFee, "currency")
        }}</strong>
      </v-row>

      <v-row no-gutters class=" d-flex justify-space-between align-top ">
        <span class="summary-text">{{
          $t("cart.checkoutSummary.taxTotal")
        }}</span>
        <span class="summary-value">{{
          $n(orderCart.taxTotal, "currency")
        }}</span>
      </v-row>

      <v-row no-gutters class="total d-flex justify-space-between align-center">
        <span>{{ $t("cart.checkoutSummary.total") }}</span>
        {{ $n(orderCart.grossTotal, "currency") }}
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: "CheckoutSummary",
  props: { orderCart: { type: Object, required: true } },
  computed: {
    netTotal() {
      return this.orderCart.orderId
        ? this.orderCart.netTotal
        : this.orderCart.totalPrice;
    }
  },
  methods: {
    removeGiftCode(giftCodeId) {
      this.$emit("removeGiftCode", giftCodeId);
    }
  }
};
</script>
