<template>
  <div class="product-grid">
    <v-container>
      <v-row>
        <v-container class="pb-0"
          ><h1 class="primary--text pt-6">
            {{ $t("navbar.navigation.cart") }}
          </h1></v-container
        >
        <Breadcrumb
          v-if="$vuetify.breakpoint.smAndUp"
          :items="breadcrumb"
          class="custom-breadcrumb"
        />
        <v-col
          cols="12"
          sm="4"
          lg="3"
          xl="2"
          v-if="!$vuetify.breakpoint.xs && viewFilter != 'list'"
          ><div>
            <h4 class="title-filter">FILTRA I PRODOTTI PER:</h4>
          </div></v-col
        ><v-col :cols="cols" :sm="sm" :lg="lg" :xl="xl"
          ><ProductSortFilter
            v-if="!$vuetify.breakpoint.xs"
            :sortFilter="sortFilter"
            :view="viewFilter"
            :actions="actions"
            :cart="true"
            @handleSortFilter="handleSortFilter"
            @handleView="handleViewFilter"/>
          <div v-else>
            <v-menu offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  color="secondary"
                  class="mb-3 w-100"
                  v-bind="attrs"
                  v-on="on"
                  :disabled="totalItems < 1"
                  >Azioni multiple
                  <v-icon right dark>
                    mdi-dots-horizontal
                  </v-icon></v-btn
                ></template
              >
              <MultipleAction :cart="true" />
            </v-menu>
            <div
              class="d-flex justify-start align-center primary lighten-2 pa-3 mx-n3 mb-3"
              style="flex:1"
            >
              <v-btn
                elevation="0"
                text
                style="flex-grow:0"
                color="primary"
                @click.stop="drawer = !drawer"
              >
                <v-icon>mdi-tune</v-icon>
              </v-btn>
              <div
                class="d-flex flex-nowrap"
                style="flex-grow:1; overflow: auto"
              >
                <!-- <v-slide-group multiple show-arrows>
                <v-slide-item
                  v-for="(selectedFilter, index) of selectedFilters"
                  :key="index"
                >
                  <v-chip
                    class="ma-2"
                    color="primary"
                    label
                    @click="chipClicked(selectedFilter)"
                  >
                    {{ selectedFilter.name }}
                  </v-chip>
                </v-slide-item>
              </v-slide-group> -->
              </div>
            </div>
          </div>
          <div style="max-height:100%" v-if="cart.cartItems">
            <ProductGridMobile
              v-if="$vuetify.breakpoint.xs"
              :products="alteredProducts"
              :totalItems="totalItems"
              :cart="true"
            ></ProductGridMobile>
            <component
              v-else
              :is="templateName"
              :products="alteredProducts"
              :totalItems="totalItems"
              :cart="true"
            ></component></div
        ></v-col>
      </v-row>

      <v-navigation-drawer
        v-model="drawer"
        app
        temporary
        floating
        right
        width="90%"
        min-width="50%"
        ><ProductFilterDraver
          :facets="facets"
          @close="drawer = false"
          @handleFacetFilterMobile="handleFacetFilterMobile"
      /></v-navigation-drawer>
    </v-container>
  </div>
</template>
<script>
import { mapActions, mapGetters, mapState } from "vuex";
import ProductSortFilter from "@/components/product/filters/ProductSortFilter.vue";
import ProductGridSmall from "@/components/product/ProductGridSmall.vue";
import ProductGridBig from "@/components/product/ProductGridBig.vue";
import ProductGridMobile from "@/components/product/ProductGridMobile.vue";
import ProductTableView from "@/components/product/ProductTableViewTest.vue";
import ProductFilterDraver from "@/components/product/filters/ProductFilterDraver.vue";
import Breadcrumb from "@/components/navigation/Breadcrumb.vue";
import MultipleAction from "./MultipleAction.vue";

export default {
  name: "CartItemList",
  components: {
    ProductSortFilter,
    ProductGridSmall,
    ProductGridBig,
    ProductGridMobile,
    ProductTableView,
    Breadcrumb,
    ProductFilterDraver,
    MultipleAction
  },
  data() {
    return {
      breadcrumb: [
        {
          text: "Home",
          disabled: false,
          href: "/"
        },
        {
          text: "Carrello",
          disabled: true,
          href: "/"
        }
      ],
      actions: [{ title: "Azione tipo 1", to: "GetImages" }],
      cols: 12,
      sm: 8,
      lg: 9,
      xl: 10,
      facets: [],
      drawer: false
    };
  },
  computed: {
    ...mapState({
      cart: ({ cart }) => cart.cart
    }),
    ...mapGetters({
      // cart: "bookmarks/sortedCart",
      sortFilter: "bookmarks/sortFilter",
      viewFilter: "bookmarks/viewFilter",
      totalItems: "cart/getItemsCount"
    }),
    templateName() {
      if (this.viewFilter === "list") {
        return "ProductTableView";
      } else if (this.viewFilter === "big-grid") {
        return "ProductGridBig";
      } else return "ProductGridSmall";
    },
    alteredProducts() {
      let alteredprod = this.cart.cartItems.map(e => {
        e.product.inCart = true;
        return e;
      });
      return alteredprod;
    }
  },
  methods: {
    ...mapActions({
      emptyCart: "cart/emptyCart",
      setCartInfo: "cart/setCartInfo"
      // setCartItemInfo: "cart/setCartItemInfo",
      // setCartInfo: "cart/setCartInfo"
    }),
    handleView(view) {
      this.view = view;
    },
    handleSortFilter(sort) {
      this.$store.dispatch("bookmarks/setFilterSort", sort);
    },
    handleViewFilter(view) {
      this.$store.dispatch("bookmarks/setFilterView", view);
      if (view === "list") {
        this.sm = 12;
        this.lg = 12;
        this.xl = 12;
      } else {
        this.sm = 8;
        this.lg = 9;
        this.xl = 10;
      }
    },
    handleFacetFilterMobile() {},
    handleCartItems() {
      this.cart.cartItems.forEach(item => {
        item.product.highlight = true;
      });
    },
    emptyCartMethod() {
      this.emptyCart();
      this.setCartInfo({
        stepOne: "",
        stepTwo: "",
        stepThree: "",
        stepFour: ""
      });
    }
  },
  mounted() {
    this.handleCartItems();
    this.handleViewFilter(this.viewFilter);
  },
  watch: {
    cart() {
      this.handleCartItems();
    }
  },
  metaInfo() {
    return {
      title: this.$t("navbar.navigation.cart")
    };
  }
};
</script>
<style scoped lang="scss">
h1 {
  font-weight: 500;
  font-size: calc(1.1285714286rem + 1.8285714286vw);
  @media screen and (min-width: 1200px) {
    font-size: 2.5rem;
  }
}
</style>
