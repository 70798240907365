<template>
  <div class="checkout">
    <v-container v-if="headerBanner.length > 0">
      <ProposalSlider :proposals="headerBanner" />
    </v-container>
    <CategoryTitle :category="category" />
    <v-container>
      <Breadcrumb class="hidden-sm-and-down px-0" :items="breadcrumbs" />
    </v-container>
    <v-container class="pt-0">
      <v-row>
        <v-col cols="12" md="8" class="cart-info pa-0 pa-sm-3">
          <CartInfoList class="cart-info-list py-8" :isCart="true" />

          <!-- visualizzazione phone -->
          <v-col
            class="summary-cards d-flex d-md-none flex-column pa-0 pa-sm-3"
            cols="12"
          >
            <GiftCode
              :giftCertificatesReceived="giftCertificatesReceived"
              @addGiftCode="addUserGiftCode"
              @sendGiftCode="sendUserGiftCode"
            />

            <CheckoutSummary
              :orderCart="cart"
              @removeGiftCode="removeUserGiftCode"
            />
          </v-col>
          <CartItemList full="true" :isCheckout="true" />

          <v-col cols="12" class="pt-0">
            <v-row no-gutters>
              <v-col cols="12">
                <p class="disclaimer-info">
                  {{ $t("checkout.disclaimer") }}
                </p>
              </v-col>
            </v-row>
            <v-alert
              v-if="error"
              type="error"
              border="left"
              transition="v-fab-transition"
            >
              {{ error }}
            </v-alert>
            <v-row class="checkout-actions" :justify="'space-between'">
              <v-col
                cols="12"
                sm="12"
                md="12"
                lg="6"
                class="d-flex justify-center justify-sm-space-between justify-lg-start"
              >
                <v-btn
                  outlined
                  color="primary"
                  depressed
                  class="bg-white mr-3 col-sm-6"
                  to="/home"
                >
                  {{ $t("cart.button.continue") }}
                </v-btn>

                <v-btn
                  outlined
                  color="primary"
                  depressed
                  class="bg-white col-sm-6"
                  :disabled="cart.totalItems == 0"
                  @click="addAllToFavorites"
                >
                  {{ $t("cart.button.addToFavorites") }}
                </v-btn>
              </v-col>

              <v-col
                cols="12"
                sm="12"
                md="12"
                lg="6"
                class="d-flex justify-center justify-sm-space-between justify-lg-end align-center"
              >
                <v-btn
                  plain
                  color="primary"
                  @click="emptyCart"
                  class="empty-cart text-decoration-underline text-capitalize mr-3 font-weight-bold col-sm-6"
                  >{{ $t("cart.button.emptyCart") }}</v-btn
                >

                <v-btn
                  color="primary"
                  class="col-sm-6"
                  @click="goToPayment"
                  depressed
                  :loading="loading"
                >
                  {{ $t("cart.button.goToPayment") }}
                </v-btn>
              </v-col>
            </v-row>
          </v-col>
        </v-col>
        <!-- visualizzazione desktop -->
        <v-col
          class="summary-cards d-none d-md-flex flex-column"
          cols="12"
          md="4"
        >
          <GiftCode
            class="mb-0 py-9 px-7"
            :giftCertificatesReceived="giftCertificatesReceived"
            @addGiftCode="addUserGiftCode"
            @sendGiftCode="sendUserGiftCode"
          />

          <CheckoutSummary
            class="py-12 px-7"
            :orderCart="cart"
            @removeGiftCode="removeUserGiftCode"
          />
        </v-col>
      </v-row>
    </v-container>

    <v-spacer class="spacer-scroll"></v-spacer>
    <v-container v-if="footerBanner.length > 0">
      <ProposalSlider :proposals="footerBanner" />
    </v-container>
  </div>
</template>

<script>
import CheckoutSummary from "@/components/cart/CheckoutSummary.vue";
import Breadcrumb from "@/components/navigation/Breadcrumb.vue";
import CartItemList from "@/components/cart/CartItemList.vue";
import GiftCode from "@/components/GiftCode.vue";
import CartInfoList from "@/components/cart/CartInfoList.vue";
import CategoryTitle from "@/components/category/CategoryTitle.vue";
import ListSelector from "@/components/lists/ListSelector.vue";
import ProposalSlider from "@/components/proposal/ProposalSlider.vue";

import RegistrationService from "~/service/userService";
import ListService from "~/service/listService";
import AnalyticsService from "~/service/analyticsService";

import productMixins from "~/mixins/product";
import login from "~/mixins/login";
import cartInfo from "~/mixins/cartInfo";
import categoryMixins from "~/mixins/category";

import { mapActions, mapState, mapGetters } from "vuex";
import { forEachCartItem, mapProposal } from "~/service/ebsn.js";

export default {
  name: "Checkout",
  mixins: [login, productMixins, cartInfo, categoryMixins],
  components: {
    CategoryTitle,
    CartItemList,
    CheckoutSummary,
    GiftCode,
    ProposalSlider,
    Breadcrumb,
    CartInfoList
  },
  data() {
    return {
      error: null,
      loading: false,
      giftCertificatesReceived: [],
      emailKey: 1,
      showAlternativesTooltip: false
    };
  },
  computed: {
    breadcrumbs() {
      let breadCrumbs = [];

      breadCrumbs.push({
        to: { name: "Home" },
        text: "Homepage",
        exact: true
      });
      breadCrumbs.push({
        to: {
          name: "Checkout",
          params: {
            pathMatch: this.category.slug
          }
        },
        text: this.category.name,
        exact: true
      });
      return breadCrumbs;
    },
    ...mapState({
      cart: ({ cart }) => cart.cart
    }),
    ...mapGetters({
      getCartStatus: "cart/getCartStatus"
    }),
    ...mapProposal({
      headerBanner: "header-banner",
      footerBanner: "footer-banner"
    })
  },

  methods: {
    ...mapActions({
      lockCart: "cart/lockCart",
      emptyCart: "cart/emptyCart",
      addGiftCode: "cart/addGiftCode",
      sendGiftCode: "cart/sendGiftCode",
      removeGiftCode: "cart/removeGiftCode"
    }),
    async addUserGiftCode(giftCodeId) {
      let cart = await this.addGiftCode(giftCodeId);
      if (cart) {
        this.getUserDetails();
      }
    },
    async sendUserGiftCode(giftCodeId) {
      let cart = await this.sendGiftCode(giftCodeId);
      if (cart) {
        this.getUserDetails();
      }
    },
    async removeUserGiftCode(giftCodeId) {
      let cart = await this.removeGiftCode(giftCodeId);
      if (cart) {
        this.getUserDetails();
      }
    },
    async getUserDetails() {
      const data = await RegistrationService.getUserDetail();
      this.giftCertificatesReceived = data ? data.giftCertificatesReceived : [];
    },
    async addAllToFavorites() {
      let _this = this;
      let res = await _this.$dialog.show(ListSelector, {
        waitForResult: true,
        fullscreen: _this.$vuetify.breakpoint.smAndDown,
        width: 300
      });
      // let newQuantity = CartService.plus(vm.product, vm.quantity);
      if (res) {
        let items = [];
        forEachCartItem(_this.cart, function(item) {
          items.push(item);
        });
        await ListService.addProductsToList(res.listId, items);
      }
    },
    async goToPayment() {
      let vm = this;
      vm.loading = true;
      try {
        await vm.lockCart();
        vm.$router.push({
          name: "Payment"
        });
      } catch (err) {
        await this.needTimeslot();
        vm.error = err.message;
      } finally {
        vm.loading = false;
      }
    }
  },
  mounted() {
    AnalyticsService.beginCheckout(this.cart);
    this.getUserDetails();
  }
};
</script>
