<template>
  <v-card flat>
    <v-row class="d-flex flex-column">
      <v-col cols="12">
        <h3>{{ $t("cart.giftCode.title") }}</h3>

        <div
          class=" d-flex justify-space-between align-center mb-2"
          v-for="gift in giftCertificatesReceived"
          :key="gift.userGiftCertificateId"
        >
          <span>{{ gift.name }}</span>

          <v-btn
            color="primary"
            depressed
            small
            v-on:click="addGiftCode(gift.userGiftCertificateId)"
          >
            USA
          </v-btn>
        </div>

        <v-row no-gutters class="d-flex align-center justify-space-between">
          <v-col cols="8">
            <v-text-field
              :placeholder="$t('cart.giftCode.inputPlaceholder')"
              hide-details="auto"
              v-model="giftCode"
              class="mr-2"
              dense
              outlined
            ></v-text-field>
          </v-col>
          <v-col cols="4">
            <v-btn
              elevation="0"
              x-small
              outlined
              color="primary"
              depressed
              class="bg-white font-weight-bold"
              v-on:click="sendGiftCode(giftCode)"
              :disabled="createDisabled"
              >{{ $t("cart.giftCode.button.apply") }}</v-btn
            >
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
export default {
  name: "GiftCode",
  props: { giftCertificatesReceived: { type: Array, required: true } },
  data() {
    return {
      giftCode: "",
      createDisabled: false
    };
  },
  methods: {
    addGiftCode(giftCodeId) {
      this.$emit("addGiftCode", giftCodeId);
    },
    sendGiftCode(giftCode) {
      this.$emit("sendGiftCode", giftCode);
    }
  }
};
</script>
